import React, { useEffect, useState } from 'react';

const TypingAnimation = () => {
  const [text, setText] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  const phrase = "Learning made easier by students, for students";

  useEffect(() => {
    let index = 0;


    const cursorInterval = setInterval(() => {
      setShowCursor(show => !show); // Toggle cursor visibility
    }, 500); // Adjust blinking speed here (in milliseconds)


    const addCharacter = () => {
      if (index < phrase.length) {
        setText(phrase.substring(0, index + 1));
        index++;
        setTimeout(addCharacter, 100); // Adjust typing speed here (in milliseconds)
      } else {
        setShowCursor(false); 
        clearInterval(cursorInterval);// Hide cursor when typing animation completes
      }
    };

    addCharacter(); // Start typing animation when component mounts

    
    return () => clearInterval(cursorInterval); // Clear interval on component unmount

  }, []); // Effect runs only once when component mounts



  return (
    <div style={{ fontSize: '40px', fontWeight: 200, position: 'relative', display: 'inline-block' }}>
      <p style={{ display: 'inline'}}>{text}</p>
      {showCursor && <span>|</span>}
    </div>
  );
};

export default TypingAnimation;

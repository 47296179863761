import React, { useState, useContext } from 'react';
import './Courses.css';
import { useDispatch, useSelector } from 'react-redux';
import {db} from "../components/firebase.js";
import { doc, updateDoc } from "firebase/firestore";
import { updateUser } from '../state/reducers.js';
import CoursesContext from '../CoursesContext'

const Courses = () => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [enrolledCourses, setEnrolledCourses] = useState(user.user.courses);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const coursesOutput = useContext(CoursesContext);
  let coursesData = coursesOutput[0];
  if(coursesData == null){
    coursesData = {};
  }

  const enrollCourse = async (id) => {
    if (!enrolledCourses.includes(id)) {
      // Add the course to the user's list of enrolled courses in the database
      const userRef = doc(db, "users", user.user.uid);
      await updateDoc(userRef, {
        courses: [...user.user.courses, id],
      });
      setEnrolledCourses([...enrolledCourses, id]);

      const updatedUser = {...user.user, courses: [...user.user.courses, id]};
      dispatch(updateUser(updatedUser));
    }
  };

  const unenrollCourse = async (id) => {
    if (enrolledCourses.includes(id)) {
      // Remove the course from the user's list of enrolled courses in the database
      const userRef = doc(db, "users", user.user.uid);
      await updateDoc(userRef, {
        courses: user.user.courses.filter(courseId => courseId !== id),
      });
      setEnrolledCourses(enrolledCourses.filter(courseId => courseId !== id));

      const updatedUser = {...user.user, courses: user.user.courses.filter(courseId => courseId !== id)};
      dispatch(updateUser(updatedUser));
    }
  
  };

  // const selectCourse = (course) => {
  //   setSelectedCourse(course);
  // };

  return (
    <div className="Courses">
      <div className="course-container">
        <div className="course-list">
        {Object.entries(coursesData).map(([key, value]) => (
          <div key={key} className="card" onMouseEnter={() => setSelectedCourse(value)}>
            <h2>{value[0]}</h2>
            {/* <p>Instructor: {value[2]}</p> */}
            <button
            style={{ color: enrolledCourses.includes(key)? 'gray': '#007bff'}}
              onClick={(e) => {
                e.stopPropagation();
                console.log(enrolledCourses.includes(key) )
                if (enrolledCourses.includes(key) ){
                  console.log("uneroll")
                  unenrollCourse(key)

                }
                else{
                  enrollCourse(key);
                }

              }}

            >
              {enrolledCourses.includes(key) ? 'Enrolled' : 'Enroll'}
            </button>
          </div>
        ))}
         
        </div>
        <div className="course-description">
          {selectedCourse ? (
            <>
              <h2>{selectedCourse[0]}</h2>
              <p>{selectedCourse[1]}</p>
              {/* <p>Instructor: {selectedCourse.instructor}</p> */}
            </>
          ) : (
            <h2>
              Select a course to see its description
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default Courses;
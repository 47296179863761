import TypingAnimation from "../components/TypingAnimation";
import Signup from "../components/Signup/Signup";
import "./Welcome.css";
import CourseBar from "../components/CourseBar/CourseBar";
import About from "../components/About/About";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Footer from "../components/Footer/Footer";
import math from '../math.png';

function Welcome({ isRegistering }) {
  const location = useLocation();
  const receivedData = location;
  useEffect(()=> {
    console.log(receivedData.state)
  })

  return (
      <div>
        <div className="container">
          <img src={math} className='mathSketch'/>
          <div className="centered-div">
            <div className="left-section">
              <TypingAnimation />
            </div>
            <div className="right-section">
              <Signup isSignup={isRegistering} data={receivedData.state}/>
            </div>
          </div>
        </div>
        <CourseBar />
        <About />
        <Footer/>
      </div>

  );
}

export default Welcome;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAXMTSe28j6qzxmg21Y4YplJJ13pNrNrSk",
  authDomain: "quickcourse-9a496.firebaseapp.com",
  projectId: "quickcourse-9a496",
  storageBucket: "quickcourse-9a496.appspot.com",
  messagingSenderId: "288852331557",
  appId: "1:288852331557:web:aac64f26601c2d040103cc",
  measurementId: "G-SFFQXLMMHS"
};


  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  console.log("Successfully initialized firebase");
  // const auth = getAuth(app)
  export const db = getFirestore(app);


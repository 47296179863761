import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { db } from "../firebase"
import { collection, query, where, getDocs, addDoc, getDoc, doc, setDoc } from "firebase/firestore";

var Verify = true
var email = ""; 
var userCredential; 

function getName(emailAdd) {
    const name = emailAdd.substring(0, emailAdd.indexOf("@")).replace(/\d+/g, '').split(".");
    const first = name[0].charAt(0).toUpperCase() + name[0].slice(1);
    const last = name[1].charAt(0).toUpperCase() + name[1].slice(1);
    return [first, last];
}

async function checkVerification(email, password) {
    const userCredential = await signInWithEmailAndPassword(getAuth(), email, password);
    return userCredential.user.emailVerified

} 


export const handleSubmit = async (e, password, mailOnly, register) => {
    if (e != email){
        mailOnly = true
        Verify = true 
        email = e
    }

    email = email.trim().toLowerCase();
    password = password.trim();
    if (!email.includes("@mail.mcgill.ca") || email.length < 16) {
        return [-3, "Invalid email", null, true ];
    }
    if (register) {
        const q = query(collection(db, "users"), where("email", "==", email));
        const querySnapshot = await getDocs(q);
        // console.log(querySnapshot)
        if (querySnapshot.size >= 1)
            return[-3, "User already exists ", null, true  ] 
    }
    
    if (mailOnly) {
        return [0, "", null, true]; // Moving on to password
    }

    if (register) {
        if (password.length < 8) {
            return [-1, "Password is too short", null, true ];
        }
        try {
            if (Verify){
                console.log("error")
                userCredential = await createUserWithEmailAndPassword(getAuth(), email, password);

                await sendEmailVerification(userCredential.user)
                .then(() => {
                    console.log("Verification email sent to " + email);
                    Verify = false 
                });
                return [10, "Verify your email", null, false]
            }
            var verification = await checkVerification(email, password)

            if (verification){
                console.log("Checking verification")
                const name = getName(email);
                const docRef = await setDoc(doc(db, "users", userCredential.user.uid), {
                    email: email,
                    first: name[0],
                    last: name[1],
                    courses: [], 
                    uid: userCredential.user.uid
                });
                const docRef2 = doc(db, "users", userCredential.user.uid);
                const docSnap = await getDoc(docRef2);
                return [2, "Welcome " + name[0],docSnap.data() ];

            }

            else return [10, "You must verify your email" ,null, false]




           
        } catch (error) {
            console.error("Error: ", error);
            return [-2, "Error, Contact Support", null, false];
        }
    }
    else{ // if logging in
        try{
            verification = await checkVerification(email, password)
            if (verification) {
                const userCredential = await signInWithEmailAndPassword(getAuth(), email, password);
                const docRef = doc(db, "users", userCredential.user.uid);
                const docSnap = await getDoc(docRef); // docSnap contains user data (docSnap.data().first, docSnap.data().last), etc.)
                return [2, "Welcome "+docSnap.data().first, docSnap.data(), false];
            }
            else {
                return [20, "Account not verified", null, false]
            }
        } catch(e){
            console.log(e);
            return [-4, "Invalid credentials", null, false];
        }
    }
};
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';
import { NavLink } from 'react-router-dom';
import './Footer.css'; // Import custom CSS for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-footer">
        <div className="row">
          <div className="col-md-6">
            <ul className="social-icons">
            <li><a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
            <li><a href="https://github.com/quickcourseca"><FontAwesomeIcon icon={faGithub} /></a></li>
            </ul>
          </div>
          <div className="col-md-12 text-center mt-3">
            <ul className="policy-links">
              <li><NavLink to={'/CookiePolicy'} >Cookie Policy</NavLink></li>
              <li><NavLink to={'/PrivacyPolicy'}>Privacy Policy</NavLink></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;


import React, { useEffect, useState} from 'react';
import './NoCourses.css';
import {db} from "../firebase"
import { collection, query, where, getDocs, addDoc, getDoc, doc, setDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';




const QuoteRef = doc(db, "Quotes", "quotes");
const PeopleRef = doc(db, "Quotes", "people")
const QuoteSnap = await getDoc(QuoteRef);
const PeopleSnap = await getDoc(PeopleRef)
console.log(QuoteSnap.data())

var q = [QuoteSnap.data().quotes, PeopleSnap.data().people]



const NoCourses = () => {
    const navigate = useNavigate();
    const [quote, setQuote] = useState(q[0][0]);
    const [person, setPerson] = useState(q[1][0]);





    useEffect( () => {


        var i  = 1
        const interval = setInterval(() => {

            setQuote(q[0][i])
            setPerson(q[1][i])
            i++ 

            if (i === q[0].length - 1){
                i = 0 
            }
        

        }, 7000); 
    
        return () => clearInterval(interval);
      }, []);


    return (
        <div className="no-courses-container">
            <div className="quote">
                <div className="quote-text">"{quote}"</div>
                <div className="quote-author">-{person}</div>
            </div>
            <button onClick={()=>{navigate('/Courses')}} className="add-courses-button">Add Courses</button>
        </div>
    );
}

export default NoCourses;
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../../state/reducers.js';
import './NavBar.css';
import { useEffect } from 'react';

function NavBar(props) {
  console.log(props.data)

  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  function logout(){
    dispatch(logoutUser());
  }
  return (
    <nav>
      <div className="left">
        <NavLink to="/">
          QuickCourse
        </NavLink>
        {/* <a href="/">
          QuickCourse
        </a> */}
      </div>
      <div className="right">
        {/* <NavLink to="/#About">About Us</NavLink> */}
        {
          user.user ? 
          (<>
          <NavLink to="/About">About Us</NavLink>
          <NavLink to="/Courses">Find Courses</NavLink>
          <NavLink to="/">{user.user.first}</NavLink>
          <NavLink to="/" onClick={logout}>Logout</NavLink>
          </>)
          : 
          (<>
            <a href="#About">About Us</a>
            {props.data ? (<NavLink to={'/'} state={{Register: false}}>Login</NavLink>) : (<NavLink to={'/'} state={{Register: true}}>Get Started</NavLink>) }
          </>)
        }
      </div>
    </nav>
  );
  }
  
export default NavBar;
import './App.css';
import Welcome from './pages/Welcome';
import Home from './pages/Home';
import Courses from './pages/Courses'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import { useSelector } from 'react-redux';
import CoursesContext from './CoursesContext';
import {db} from './components/firebase';
import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import About from './components/About/About';
import Cookie from './pages/Policies/Cookie';
import Privacy from './pages/Policies/Privacy';


function App() {
  const user = useSelector(state => state.user);
  const [courses, setCourses] = useState([])

  const [Register, setRegister] = useState(true);

  const handleRegister = (data) => {
    setRegister(data);
  };


  useEffect(() => {
    const fetchCourses = async () => {
      const coursesCollection = collection(db, 'courses');
      const coursesSnapshot = await getDocs(coursesCollection);
      const coursesList = coursesSnapshot.docs.map(doc => doc.data());
      setCourses(coursesList);
    };
    fetchCourses();
  }, []);
  return (
    <CoursesContext.Provider value={courses}>
      <NavBar data={Register} />
      <Routes>
        <Route path="/Home" element={ <Home/> } />
        <Route path="/Courses" element={<Courses/>} />
        <Route path="/About" element={<About/>} />
        <Route path="/" element={<Welcome isRegistering={handleRegister}/>} />
        <Route path="/CookiePolicy" element={<Cookie/>}/>
        <Route path="/PrivacyPolicy" element={<Privacy/>}/>


      </Routes>
    </CoursesContext.Provider>
  );
}

export default App;

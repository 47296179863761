import './Home.css';
import { useSelector } from 'react-redux';
import NoCourses from '../components/Courses/NoCourses'
import OnlyCourses from '../components/Courses/OnlyCourses';

const Home = () => {
  const user = useSelector(state => state.user);

  return (
    <div>
    {user.user.courses.length === 0 ? 
      (<NoCourses/>)
      :(<OnlyCourses/>)}

      </div>
)};

export default Home;

// PrivacyPolicy.js
import React from 'react';
import "./Policy.css"

const Privacy = () => {
  return (
    <div className='policy-container'>
      <h1>Privacy Policy</h1>
      <p>This Privacy Policy governs the manner in which QuickCourse collects, uses, maintains, and discloses information collected from users (each, a "User") of the www.quickcourse.ca website ("Site").</p>
      <h2>Personal Identification Information</h2>
      <p>We collect personal identification information from Users only if they voluntarily submit such information to us. The types of personal information collected may include:</p>
      <ul>
        <li>University email address</li>
        <li>First name</li>
        <li>Last name</li>
      </ul>
      <p>Users may visit our Site anonymously and choose not to provide this information, but it may prevent them from engaging in certain Site-related activities.</p>
      <h2>How We Use Your Information</h2>
      <p>The information we collect is used for the following purposes:</p>
      <ul>
        <li>To personalize user experience</li>
        <li>To improve our Site</li>
        <li>To send periodic emails</li>
      </ul>
      <h2>Protecting Your Information</h2>
      <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, university email address, first name, last name, and data stored on our Site.</p>
      <h2>Sharing Your Personal Information</h2>
      <p>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>
      <h2>Your Consent</h2>
      <p>By using our Site, you consent to the collection and use of information as outlined in this Privacy Policy.</p>
      <h2>Changes to This Privacy Policy</h2>
      <p>QuickCourse has the discretion to update this Privacy Policy at any time. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:</p>
      <p>support@quickcourse.ca</p>
    </div>
  );
}

export default Privacy;

import './OnlyCourses.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CoursesContext from '../../CoursesContext';
import { useContext } from 'react';

const OnlyCourses = () => {
    const user = useSelector(state => state.user);
    const coursesOutput = useContext(CoursesContext);
    let coursesData = coursesOutput[0];
    if(coursesData == null){
        coursesData = {};
    }
    return (
        <div className='home'>
            <h2 className='greeting'>
                Hi,<br/>{user.user.first + ' ' + user.user.last}
            </h2>
            <div className='midpage'>
                {/* <div className='courses'>
                    <h3>My Courses</h3>
                    {Object.entries(coursesData).map(([key, value]) => (
                        <div className='course' key={key}>
                            <h4>{value[0]}</h4>
                        </div>
                    ))}
                </div> */}
                <div className='rightAlign'>
                    <div className='progress'>
                        <h1 style={{fontSize: '4rem'}}>
                            Course content coming soon
                            <br/>
                            Stay tuned!
                        </h1>
                    </div>
                    {/* <div className='findCourses'>
                        <Link to='/courses' className='findCourses'>
                            Find Your Courses
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>
    );

}
export default OnlyCourses;

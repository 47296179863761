import CourseLabel from './CourseLabel';
import './Courses.css';
import CoursesContext from '../../CoursesContext';
import { useContext } from 'react';

function CourseBar() {
    const coursesOutput = useContext(CoursesContext);
    let coursesData = coursesOutput[0];
    if(coursesData == null){
        coursesData = {};
    }
    return (
        <>
            <div className="courseBar">
                {Object.entries(coursesData).map(([key, value]) => (
                    <CourseLabel key={key} name={value[0]} />
                    
                ))}
            </div>
        </>
    );
  }
  
export default CourseBar;
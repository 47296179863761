import React, { useState, useEffect, useRef } from 'react';
import "./Signup.css"
import { handleSubmit } from './handleSignup';
import '../firebase.js';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../state/reducers.js';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Signup = ({ isSignup, data }) => {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPassbox, setShowPassbox] = useState(false);
  const [register, setRegister] = useState(true); 
  const [button, setButton] = useState(register ? "Verify" : "Log in");
  const [title, setTitle] = useState(register ? "Sign up" : "Log in");
  const [text, setText] = useState(register ? "Have an account? Log in" : "New? Sign up");

  const dispatch = useDispatch();
  const home = () => navigate('/home');

  useEffect(()=>{
    if (data !== null) {
      setRegister(data.Register)

    }

  },[data])





  useEffect(() => {
    if (user.user != null) {
      navigate('/home')
    }
  
    if (register){
      setTitle("Sign up")
      setButton("Verify")
      setText("Have an account? Log in")
    }
    else {
      setTitle("Log in")
      setButton("Log in")
      setText("New? Sign up")
    }
    isSignup(register)
  }, [register]);

  useEffect(() => {
    if (showPassbox && passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [showPassbox]);

  const passwordRef = useRef();
  const userRef = useRef();

  const submitForm = async (event) => {
    event.preventDefault();
    const [code, responseMessage, user, verify] = await handleSubmit(email, password, !showPassbox, register); // last argument true for register, false for login
    setMessage(responseMessage);

    if (user != null) {
      home()

    }
    if (register){
      if (verify) {
        setButton("Verify")

      }
      else {setButton("Sign Up")}
    }
    else {
      setButton("Log in")
    }

    if (code === 0){
      setShowPassbox(true);
    }
    if (code === 2){ // login success
      dispatch(updateUser(user));
    }
  };

  return (
    <div className="signup">
      <p className = "title">{title}</p>
      <form onSubmit={submitForm}>
        <input
          ref={userRef}
          type="text" /* Specifying type="email" will add automatic input checking, we want to do all of the input checking ourselves */
          placeholder="first.last@mail.mcgill.ca"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && showPassbox) {
              e.preventDefault();
              passwordRef.current.focus();
            }
          }}
        />
        <br/>
        {showPassbox &&
        <input
          ref={passwordRef}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              submitForm(e);
            }
          }}
        />}
        <br/>
      <button className="button" type="submit">{button}</button>
    </form>
    <p className="login" onClick={() => {
      setRegister(!register)

    }}>{text}</p>
    <p className='errorm'>{message}</p>

  </div>
  );
};

export default Signup
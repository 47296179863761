import React from 'react';
import "./About.css"
import mika from "./mika.png"
import karan from "./karan.jpg"




const PersonProfile = ({ name, description, imageUrl }) => {
  return (
    <div className={'person-profile'}>
      <img src={imageUrl} alt={name} />
      <h2>{name}</h2>
      <p>{description}</p>
    </div>
  );
};
const Blob = () => {
  return (
    <div className={'blob-container'}>
      <div className={'blob'}>
      <svg className={'blob-svg'} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path fill="#3498db" d="M62.4,-15.1C68.8,4.8,54.3,32,32.7,41.9C11,51.7,-17.7,44.2,-33.6,24.1C-49.5,4,-52.4,-28.7,-39.1,-46.4C-25.7,-64,-0.1,-66.7,23.1,-60.2C46.2,-53.7,69.6,-38,72.9,-18.4C76.2,1.1,59.5,25.6,39.5,32.6C19.5,39.5,-3.8,28.9,-16.6,15.6C-29.5,2.3,-31.9,-12.7,-27.8,-23.7C-23.7,-34.6,-13.1,-41.5,0.6,-41.5C14.4,-41.5,28.8,-34.6,38.7,-21.7C48.5,-8.8,54,-1.9,62.4,-15.1Z" transform="translate(100 100)" />
        </svg>
      </div>
    </div>
  );
};

const About = () => {



return (
    <div id='About' className={'about-page'}>
      <h1>About Us</h1>
      <div className={'profiles'}>
        <PersonProfile
          name="Karan Gandhi"
          description="Honours Computer Science"
          imageUrl={karan}
        />
        <PersonProfile
          name="Mika Vohl"
          description="Honours Computer Science and Physics"
          imageUrl={mika}
        />
      </div>
      {/* <Blob/> */}
    </div>
  );
};

export default About;

// CookiePolicy.js
import React from 'react';
import "./Policy.css";

const Cookie = () => {
  return (
    <div className="policy-container">
      <h1>Cookie Policy</h1>
      <p>This Cookie Policy explains how QuickCourse uses cookies on the www.quickcourse.ca website ("Site").</p>
      <h2>What Are Cookies?</h2>
      <p>Cookies are small text files that are stored on your computer or mobile device when you visit a website. They are widely used to make websites work more efficiently and to provide information to the owners of the site.</p>
      <h2>How We Use Cookies</h2>
      <p>Quickcourse uses cookies to:</p>
      <ul>
        <li>Remember account login details: We use cookies to remember your account login information so you don't have to enter it each time you visit our Site.</li>
        <li>Remember course information: We may use cookies to remember course preferences or progress to enhance your experience on our course website.</li>
      </ul>
      <h2>Your Consent</h2>
      <p>By using our Site, you consent to the use of cookies as described in this Cookie Policy.</p>
      <h2>Managing Cookies</h2>
      <p>Most web browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may impair your overall user experience, as it will no longer be personalized to you.</p>
      <h2>Third-Party Cookies</h2>
      <p>We do not use third-party cookies on our Site.</p>
      <h2>Changes to This Cookie Policy</h2>
      <p>QuickCourse has the discretion to update this Cookie Policy at any time. We encourage Users to frequently check this page for any changes to stay informed about how we are using cookies. You acknowledge and agree that it is your responsibility to review this Cookie Policy periodically and become aware of modifications.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions about this Cookie Policy, the practices of this site, or your dealings with this site, please contact us at:</p>
      <p>support@quickcourse.ca</p>
    </div>
  );
}

export default Cookie;
